.animate-charcter {
    text-transform: uppercase;
    background-image: linear-gradient(-225deg,
            #231557 0%,
            #44107a 29%,
            #ff1361 67%,
            #fff800 100%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    animation-direction: reverse !important;
    display: inline-block;
    /* font-size: 190px; */
}

@keyframes textclip {
    to {
        background-position: 200% center;
    }
}




.wrapper-about-us {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    /* background: rgb(223, 215, 215); */
    margin: 10px auto;
    padding: 5px 30px;
    width: 800px;
    /* box-shadow: 0 0 5px black; */
  }

  .hr {
    display: flex;
    align-items: center;
  }

  .mission-txt {
    font-size: 18px;
    font-weight: 500px;
    font-style: italic;
    margin-top: 0;
  }

  .about-us  div img {
    /* width: 500px; */
    /* filter: drop-shadow(0 10px 5px black); */
  }

  .faculties {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  .unit {
    margin: 25px;
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .unit img {
    border-radius: 100px;
    width: 150px;
    height: 150px;
    margin-bottom: 10px;
  }

  .unit p {
    text-align: left;
    margin: 2px;
  }

  .unit p:first-of-type {
    font-weight: bolder;
    margin-bottom: 5px;
  }

  @media screen and (max-width:820px) {
    .wrapper-about-us {
      width: 90%;
      padding: 5px 30px;
    }
  }
  

  .social-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 50px;
    height: 50px;
    margin: 0 0.5rem;
    border-radius: 50%;
    cursor: pointer;
    font-family: "Halvetica Neue",sans-serif,"Arial";
    font-size: 1.5rem;
    text-decoration: none;
    transition: all 0.15s ease;
    box-shadow: inset 1px 1px 2px #90a4ae,
                inset -1px -1px 2px white,
                5px 5px 10px #90a4ae,
                -5px -5px 10px white;
    border: 6px solid rgba(230,228,228);            
}

.social-icon--instagram{
    background: #E1306C;
    color: white;
}

.social-icon--linkedin{
    background: #0072b1;
}