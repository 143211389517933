.tra-success-box {
    width: 800px !important;
}

.alerticon {
    font-size: xx-large;
}

.notify-successbox {
    color: green;
}

.notify-failbox {
    color: red;;
}

.tra-success-box,.tra-fail-box {
    width: 600px !important;
}

.tran-table-col-1 {
    font-weight: 600;
}

@media (max-width: 1000px) {

    .tra-success-box, .tra-fail-box {
        width: 95% !important;
    }
    .success-msg {
        font-size: 20px;
    }
    .alerticon  {
        font-size: 18px;
    }
}


 .container-404-page {
     width: 300px;
     margin: 0 auto;        
     margin-top: 5%;
 }


 .container-404-page  h1 {
      font-size: 80px;
      font-weight: 800;
      text-align: center;
      font-family: 'Roboto', sans-serif;
  }
 .container-404-page h2 {
      font-size: 25px;
      text-align: center;
      font-family: 'Roboto', sans-serif;
  }

   .container-404-page p {
      text-align: center;
      font-family: 'Roboto', sans-serif;
      font-size: 12px;
  }

.insta-link {
    color: #4c57f7 !important;
}