.addPart {
    margin-top: 10px;
}

.registration-form {
    padding: 30px;
}


.form-text {
    font-size: 0.6em !important;
}

.checkout {
    width: 28%;
}

.total {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.Subtotal {
    font-size: 22px;
    font-family: 'Open Sans';
    font-weight: 700;
    color: #202020;
}

.items {
    font-size: 16px;
    font-family: 'Open Sans';
    font-weight: 500;
    color: #909090;
    line-height: 10px;
}

.total-amount {
    font-size: 36px;
    font-family: 'Open Sans';
    font-weight: 900;
    color: #202020;
}

.checkout-button {
    margin-top: 5px;
    width: 100%;
    height: 40px;
    border: none;
    background: linear-gradient(to bottom right, #B8D7FF, #8EB7EB);
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Open Sans';
    font-weight: 600;
    color: #202020;
}

@media (max-width: 1000px) {
    .checkout {
            width: 100%;
        }
}