@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

body {
    min-height: 100vh;
    width: 100%;
    background: #EEECEB;
}

footer {
    /* position: fixed; */
    background: #140B5C;
    width: 100%;
    left: 0;    
}



footer .content {
    max-width: 1250px;
    margin: auto;
    padding: 30px 40px 27px 40px;
}

footer .content .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-bottom: 50px; */
}

.content .top .logo-details {
    color: #fff;
    font-size: 30px;
}

.content .top .media-icons {
    display: flex;
}

.content .top .media-icons a {
    height: 40px;
    width: 40px;
    margin: 0 8px;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    color: #fff;
    font-size: 17px;
    text-decoration: none;
    transition: all 0.4s ease;
}

.top .media-icons a {
    background: #fff;
}

.contact-mobile-no a, .contact-email a {
    color: darkgrey !important;

}


@media (max-width: 700px) {
    footer {
        position: relative;
    }

    .content .top .logo-details {
        font-size: 26px;
    }

    .content .top .media-icons a {
        height: 35px;
        width: 35px;
        font-size: 14px;
        line-height: 35px;
    }
}

@media (max-width: 520px) {
    footer::before {
        top: 145px;
    }

    footer .content .top {
        flex-direction: column;
    }

    .content .top .media-icons {
        margin-top: 16px;
    }
}