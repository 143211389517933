#logo {
    text-align: center;
    padding: 10px;
}

#logo img {
    height: 40px;
    background: #ffffff66;
    border-radius: 5px;
    padding: 0px 5px 0px 5px;
}

.scan-space {
     width: 100%;
}


@media(max-width: 600px) {
    .qr-scanner {
        display: block !important;
    }   

    #reader {
        width: 100%;
        margin: 0px;
        padding: 0px;
    }
}

#html5qr-code-full-region__dashboard {
    margin-bottom: 15px;
}


#html5qr-code-full-region__camera_permission_button, #html5qr-code-full-region__dashboard_section_swaplink {
    background-color: DodgerBlue;
        border: none;
        color: white !important;
        padding: 0.70rem 0.8rem !important;
        cursor: pointer;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        border-width: 1px;
        border-color: transparent;
        border-radius: 0.375rem;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
        margin-bottom: 20px;
}

#html5qr-code-full-region__dashboard_section_fsr, #html5qr-code-full-region__dashboard_section_csr {
        margin-bottom: 20px;
}



