
.auto-grid {
    --auto-grid-min-size: 17rem;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
    grid-gap: 1rem;
}

.photo {
    font-size: 1.2rem;
    list-style: none;
    width: 300px;
    height: 300px;
}

.photo img {
    width: 300px;
    height: 300px;
    transition: transform .2s;
    /* transition: transform 40s ease-in-out 2s, opacity 6s ease-in-out 2s; */
}

.photo img:hover {
    transform: scale(1.5);    
}


@media (max-width: 1000px) {
    
.auto-grid {
    --auto-grid-min-size: 15rem;
}
    .photo {
        text-align: center;
        list-style: none;
    }

    .photo img {
        width: 300px;
        height: 300px;
        pointer-events: none;
    }        
    
    .photo:active {
        transform: scale(1.3);
    }
}


